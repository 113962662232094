
export default {
  props: {
    icon: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    },
    register: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    link: {
      fontSize: 'sm',
      cursor: 'pointer',
      $inline: {
        display(val) {
          return val ? null : 'block'
        },
        marginBottom(val) {
          return val ? null : (this.icon ? null : 'sm')
        }
      }
    }
  }
}
