import { make } from 'vuex-pathify'

const state = () => ({
  filters: {}
})

const mutations = {
  ...make.mutations(state),

  SET_FILTERS(state, { namespace, value }) {
    state.filters[namespace] = value
  },

  CLEAR_FILTERS(state, { namespace, defaults }) {
    state.filters[namespace] = defaults
  }
}

const actions = {
  ...make.actions(state),

  setFilters({ commit }, options) {
    commit('SET_FILTERS', options)
  },

  clearFilters({ commit }, options) {
    commit('CLEAR_FILTERS', options)
  }
}

const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
