export default function({ store }, inject) {
  const sharedCart = {
    get id() {
      return store.state.sharedCart.id
    },
    get data() {
      return store.state.sharedCart.data || {}
    },
    get member() {
      return store.state.sharedCart.member
    },
    get exists() {
      return Boolean(this.id)
    },
    setCart(data) {
      store.dispatch('sharedCart/update', data)
    },
    setMember(email) {
      store.dispatch('sharedCart/login', email)
    },
    setOwner(email) {
      store.dispatch('sharedCart/setOwner', email)
    },
    clearCart() {
      store.dispatch('sharedCart/clear')
    }
  }

  inject('sharedCart', sharedCart)
}
