import { make } from 'vuex-pathify'

const state = () => ({
  id: null,
  data: {},
  member: null,
  owner: null
})

const getters = make.getters(state)

const mutations = {
  ...make.mutations(state),

  SET_OWNER(state, data) {
    state.member = null
    state.owner  = data
  },

  SET_MEMBER(state, data) {
    state.owner  = null
    state.member = data
  },

  UPDATE_DATA(state, { id, ...data }) {
    state.id   = id
    state.data = { id, ...data }
  },

  CLEAR_DATA(state) {
    state.id     = null
    state.data   = {}
    state.member = null
    state.owner  = null
  }
}

const actions = {
  ...make.actions(state),

  update({ commit }, data) {
    commit('UPDATE_DATA', data)
  },

  login({ commit }, data) {
    commit('SET_MEMBER', data)
  },

  clear({ commit }) {
    commit('CLEAR_DATA')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
